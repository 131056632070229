import { GetStaticProps } from 'next';
import ScreenError from '@/components/ScreenError/ScreenError';
import React from 'react';
import Icon404 from '../public/static/svg/404.svg';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

const Custom404 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>404 | Butaca</title>
      </Head>
      <ScreenError
        code="404"
        Icon={Icon404}
        title={t('404')}
        subtitle={t('404Message')}
      />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'profile'])),
    },
  };
};

export default Custom404;
